import { render, staticRenderFns } from "./property-list.vue?vue&type=template&id=9eda638c"
import script from "./property-list.vue?vue&type=script&lang=ts"
export * from "./property-list.vue?vue&type=script&lang=ts"
import style0 from "./property-list.vue?vue&type=style&index=0&id=9eda638c&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\nimbusworkspace\\nimbus-web-2\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9eda638c')) {
      api.createRecord('9eda638c', component.options)
    } else {
      api.reload('9eda638c', component.options)
    }
    module.hot.accept("./property-list.vue?vue&type=template&id=9eda638c", function () {
      api.rerender('9eda638c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/dashboard/config/property/property-list.vue"
export default component.exports