import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlUploadModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { generateUUID } from '@common-src/utils/base_util';

export class PropertyEntityModel extends BaseEntityModel {
    constructor(propertyType?: string, isEdit: boolean = false) {
        super();
        this.type = propertyType;
        this.isEdit = isEdit;
        this.active = true;
    }

    @FormControl({
        label: '标识符',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    code: string = undefined;

    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    /**
     * 用户更新名称时暂存的值
     * 取消后，还原原始值
     */
    tempName: string = undefined;
    type: string = undefined;
    // code: string = undefined;
    active: boolean = false;
    order: number = undefined;
    isEdit: boolean = false;
    unit: string = undefined;
    sort: string | number = undefined;

    static getTableColumns():Array<any> {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' },
                width: 320,
                ellipsis: true
            },
            {
                title: '标识符',
                dataIndex: 'code'
            },
            {
                title: '排序',
                dataIndex: 'sort',
                scopedSlots: { customRender: 'sort' }
            },
            {
                title: '是否启用',
                dataIndex: 'enable',
                scopedSlots: { customRender: 'enable' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.active = !!json.active;
        this.tempName = this.name;
        return this;
    }

    toService() {
        return {
            id: this.id,
            code: this.code || generateUUID(),
            name: this.name,
            active: this.active,
            order: this.order || 1,
            unit: this.unit,
            type: this.type
        };
    }
}
export class ImportExcelEntityModel extends BaseEntityModel {
    @FormControl({
        label: '上传',
        type: FormControlType.UPLOAD,
        accept: '.xlsx',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true
    } as FormControlUploadModel)
    file: Array<any> = undefined;

    toService() {
        const formData = new FormData();
        formData.append('file', _.get(this.file, '[0].file'));

        return formData;
    }
}
export class PropertyQueryModel extends QueryPageModel {
    constructor(propertyType: string) {
        super();
        this.type = propertyType;
    }
    type: string = undefined;
}
