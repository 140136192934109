

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import { ImportExcelEntityModel } from '@common-src/entity-model/property-entity';
import PropertyService from '@common-src/service/property';

@Component
export default class ImportExcelComponent extends FormDialogComponent<ImportExcelEntityModel> {
    dialogOpen(importModel:ImportExcelEntityModel, server:ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '导入';
        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(importModel, viewMode);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((res) => {
                PropertyService.importExcel(this.jtlForm.formModel).then(res => {
                    this.showMessageSuccess('导入成功');
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
