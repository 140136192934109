
import { PropertyEntityModel, PropertyQueryModel, ImportExcelEntityModel } from '@common-src/entity-model/property-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, del, download } from './request';

const URL_PATH = `${COMMON_BASE_REQUEST_PATH}/property`;

class PropertyService implements ICRUDQ<PropertyEntityModel, PropertyQueryModel> {
    async create(model: PropertyEntityModel):Promise<PropertyEntityModel> {
        const url = `${this.getUrlByType(model.type)}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<PropertyEntityModel> {
        throw new Error('Not implement');
    }

    async update(model: PropertyEntityModel):Promise<PropertyEntityModel> {
        const url = `${this.getUrlByType(model.type)}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: PropertyEntityModel):Promise<PropertyEntityModel> {
        const url = `${this.getUrlByType(model.type)}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: PropertyQueryModel, page?: number, limit?: number):Promise<any> {
        const params: any = Object.assign({ page, limit }, query?.toService());

        if (!params.type) {
            throw new Error('Has no entityType');
        }
        // let url = null;
        // let res = null;
        // if (params.type === 'unit') {
        //     url = `${COMMON_BASE_REQUEST_PATH}/unit/list`;
        //     res = await post(url);
        // } else {
        //     url = `${URL_PATH}/list`;
        //     res = await post(url, params);
        // }
        const url = `${URL_PATH}/list`;
        const res = await post(url, params);
        return _.map(res.items || res, item => item = new PropertyEntityModel(params.type).toModel(item));
    }

    async importExcel(model: ImportExcelEntityModel) {
        const url = `${URL_PATH}/importExcelV2`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async exportExcel() {
        const url = `${URL_PATH}/exportV2`;
        const res = await download(url, null, 'post');
        // const res = await post(url);
        return res;
    }

    getUrlByType(type: string) {
        // if (type === 'unit') {
        //     return `${COMMON_BASE_REQUEST_PATH}/unit`;
        // }
        return URL_PATH;
    }
    async changeSort(model: PropertyEntityModel) {
        const url = `${URL_PATH}/changeSort`;
        const res = await post(url, {
            id: model.id,
            sort: model.sort
        });
        return res;
    }
}

export default new PropertyService();
