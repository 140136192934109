


































































import { Component, Watch } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
// import TableComponent from '@common-src/mixins/table-component';
import { PropertyEntityModel, PropertyQueryModel, ImportExcelEntityModel } from '@common-src/entity-model/property-entity';
import PropertyService from '@common-src/service/property';
import { UserStoreModule } from '@common-src/store/modules/user';
import ImportExcelDialog from './import-excel-dialog.vue';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'import-excel-dialog': ImportExcelDialog
    }
})
export default class PropertyListComponent extends TableDialogFormComponent<PropertyEntityModel, PropertyQueryModel> {
    PropertyEntityModel = PropertyEntityModel;
    ImportExcelEntityModel = ImportExcelEntityModel;
    UserStoreModule =UserStoreModule;
    onChangeSort;

    get PropertyType(): string {
        return this.$route.params.type as string;
    }
    /**
     * 是否是超级管理员
     */
    get IsSuperAdmin(): boolean {
        return _.get(UserStoreModule.UserInfo, 'IsSuperAdmin');
    }
    created() {
        this.initTable({
            service: PropertyService,
            queryModel: new PropertyQueryModel(this.PropertyType),
            tableColumns: PropertyEntityModel.getTableColumns()
        });
        this.onChangeSort = _.debounce(this.changeSort, 500);
        this.getList();
    }

    @Watch('$route.params.type', {})
    routerChange(newVal: string): void {
        if (newVal) {
            (this.queryModel as any).type = newVal;
        }
    }

    // addClick() {
    //     if (!this.listData) {
    //         this.listData = [];
    //     }
    //     this.listData.unshift(new PropertyEntityModel(this.PropertyType, true));
    //     this.focusFirstInput();
    // }

    // editClick(property: PropertyEntityModel) {
    //     property.isEdit = true;
    //     this.focusFirstInput();
    // }
    // cancelAddClick(property: PropertyEntityModel, index: number) {
    //     if (property.id) {
    //         property.isEdit = false;
    //     } else {
    //         this.listData.splice(index, 1);
    //     }
    // }
    async changeSort(property) {
        this.listLoading = true;
        try {
            await PropertyService.changeSort(property);
            this.listLoading = false;
        } catch (error) {
            this.listLoading = false;
        }
        this.getList();
    }
    enableOnChange(property: PropertyEntityModel) {
        this.saveProperty(property).catch(err => {
            property.active = !property.active;
        });
    }
    // orderClick(property: PropertyEntityModel, direction: string, index: number) {
    //     const model1 = _.cloneDeep(property);
    //     let model2 = null;
    //     if (direction === 'up') {
    //         if (index === 0) {
    //             return;
    //         }
    //         model2 = _.cloneDeep(this.listData[index - 1]);
    //     } else {
    //         if (index === this.listData.length - 1) {
    //             return;
    //         }
    //         model2 = _.cloneDeep(this.listData[index + 1]);
    //     }
    //     const tempOrder = model1.order;
    //     model1.order = model2.order;
    //     model2.order = tempOrder;
    //     this.listLoading = true;
    //     Promise.all([PropertyService.update(model1), PropertyService.update(model2)]).then(res => {
    //         this.getList();
    //     }).finally(() => {
    //         this.listLoading = false;
    //     });
    // }
    saveClick(property: PropertyEntityModel) {
        if (!property.tempName) {
            this.showMessageWarning('请输入名称');
            return;
        }
        property.name = property.tempName;
        this.saveProperty(property).then(res => {
            property.isEdit = false;
        }).catch(err => {
            property.active = !property.active;
        });
    }
    saveProperty(property: PropertyEntityModel, reload: boolean = false) {
        this.listLoading = true;
        return PropertyService.update(property).then(res => {
            if (reload) {
                this.getList();
            }
        }).finally(() => {
            this.listLoading = false;
        });
    }
    focusFirstInput() {
        this.$nextTick(() => {
            const firstInput: any = _.first(document.getElementsByClassName('property-name-input'));
            if (firstInput) {
                firstInput.focus();
            }
        });
    }
    importExcel() {
        const importModel = new ImportExcelEntityModel();
        (this.$refs.importExcelDialog as ImportExcelDialog).dialogOpen(importModel, null, ViewModeType.NEW);
    }

    exportExcel() {
        this.startFullScreenLoading('正在导出...');
        PropertyService.exportExcel().then(res => {
            this.showMessageSuccess('导出数据字典成功');
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    deleteClick(model: PropertyEntityModel): Promise<boolean> {
        // if (!UserStoreModule.CurrentProjectId && model['systemDefault']) {
        //     this.showMessageWarning('平台级数据禁止删除');
        //     return;
        // }
        this.startFullScreenLoading('正在删除');
        return this.service.delete(model).then((ret) => {
            this.removeModel(model);
            this.showMessageSuccess('删除成功');
            return true;
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}

